import { AssignmentLeaveTypeEnum, ConfigurationInterface, FeatureEnum, FeatureFlagEnum } from '@nutt/configuration';

// TODO : All feature flags to enum

export const configuration: Readonly<ConfigurationInterface> = {
  tenant: { brand: 'Nutt', logoUrl: 'assets/logo.svg' },

  branches: [
    { name: 'Gramsbergen', value: 'gramsbergen' },
    { name: 'Zwolle', value: 'zwolle' },
  ],

  features: [
    // The form feature is never properly tested beyond its pilot phase, so it is disabled for now.
    { feature: FeatureEnum.Forms, isDisabled: true },
    {
      feature: FeatureEnum.Employees,
      flags: {
        [FeatureFlagEnum.LinkedWithLoket]: true,
        [FeatureFlagEnum.ChildrenField]: true,
        [FeatureFlagEnum.LeaveBalance]: true,
      },
    },
    {
      feature: FeatureEnum.Planning,
      flags: {
        [FeatureFlagEnum.Viewer]: true,
        [FeatureFlagEnum.DefaultWholeDay]: true,
        [FeatureFlagEnum.CanTransferEmployees]: true,
      },
      metadata: {
        assignableLeaveTypes: [
          AssignmentLeaveTypeEnum.School,
          AssignmentLeaveTypeEnum.Sick,
          AssignmentLeaveTypeEnum.Leave,
          AssignmentLeaveTypeEnum.Doctor,
          AssignmentLeaveTypeEnum.ParentLeave,
        ],
        defaultStartOfWorkDay: '08:00',
        defaultEndOfWorkDay: '17:00',
      },
    },
    {
      feature: FeatureEnum.Projects,
      flags: { [FeatureFlagEnum.ProjectTypes]: true },
      metadata: {
        projectTypes: [
          { value: 'P', label: 'Project' },
          { value: 'S', label: 'Servicebon' },
        ],
      },
    },
    { feature: FeatureEnum.Education },
  ],
};
